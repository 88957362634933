/**
 * user_type：
 */

export const cannotAccessList = {
  
}

export const defaultPage = {
  admin: '/setting',
}
