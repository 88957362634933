<template>
  <!-- https://ithelp.ithome.com.tw/articles/10223518 -->
  <!-- $emit used when child element want to pass some data to parent element-->
  <v-navigation-drawer :value="isDrawerOpen" app floating width="260" class="app-navigation-menu" :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)">
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 py-5">
      <router-link :to="defaultLink" class="d-flex align-center text-decoration-none">
        <v-slide-x-transition>
          <v-img
            :src="require('@/assets/images/logo.jpeg')"
            
            max-width="150px"
            alt="logo"
            contain
          ></v-img>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list expand shaped class="vertical-nav-menu pr-5">
      <template v-for="(link, index) in navLink">
        <NavMenuGroup 
          v-if="link.isGroup && link.show" 
          :title="link.title" 
          :key="index"
        >
          <nav-menu-link 
            v-for="(item, linkIndex) in link.subGroup" 
            :key="`link_${linkIndex}`" 
            :title="item.title"
            :to="item.name"
            v-if="item.show"
          ></nav-menu-link>
        </NavMenuGroup>
        <nav-menu-link v-else-if="!link.isGroup && link.show" :key="`item_${index}`" :title="link.title"
          :to="link.name"></nav-menu-link>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import NavMenuLink from './components/NavMenuLink.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import { cannotAccessList, defaultPage } from '@/assets/cannotAccessList'

export default {
  components: {
    NavMenuLink,
    NavMenuGroup,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    navLink: [],
    adminType: 'staff',
    defaultLink: '',
  }),
  methods: {
    initializeNavLink() {
      this.navLink = [
        {
          isGroup: false,
          title: '頁面管理',
          name: { name: 'Setting' },
          show: !cannotAccessList['Setting'] || !cannotAccessList['Setting'].includes(this.adminType),
        },
        {
          isGroup: false,
          title: '最新消息',
          name: { name: 'NewsList' },
          show: !cannotAccessList['NewsList'] || !cannotAccessList['NewsList'].includes(this.adminType),
        },
        {
          isGroup: false,
          title: '服務預約',
          name: { name: 'AppointmentList' },
          show: !cannotAccessList['AppointmentList'] || !cannotAccessList['AppointmentList'].includes(this.adminType),
        },
        {
          isGroup: false,
          title: '客戶查詢',
          name: { name: 'EnquiryList' },
          show: !cannotAccessList['EnquiryList'] || !cannotAccessList['EnquiryList'].includes(this.adminType),
        },
      ]

      this.defaultLink = defaultPage[this.adminType]
    },
  },
  async created() {
    const check = await this.checkUserData()
    if (check) {
      this.adminType = this.getUserType()
      this.initializeNavLink()
    }
  },
}
</script>

<style>
.v-navigation-drawer__content {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  max-height: 100%;
}
</style>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;

  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  background-color: #fafafa !important;

  .v-list {
    flex: 1 !important;

    .v-list-item {
      &.vertical-nav-menu-link {
        ::v-deep .v-list-item__icon {
          .v-icon {
            transition: none !important;
          }
        }
      }
    }
  }
}

.vertical-nav-menu {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
}

.v-application.theme--dark .app-navigation-menu {
  background-color: #272727 !important;
}

.copyright {
  display: block;
  padding: 10px 6px 10px 16px;
  font-size: 0.7rem;
  line-height: 1.5;
}
</style>
