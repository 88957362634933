const displayError = (res) => {
    if (res.data !== undefined && res.data !== null) {
        if (typeof res.data === 'string') {
            return res.data;
        } else {
            return JSON.stringify(res.data);
        }
    }

    return JSON.stringify(res);
}

export default {
    install(Vue, options) {
        Vue.prototype.$Fetcher = new (function () {
            const DataValid = Vue.prototype.$validate.DataValid;
            const devLog = Vue.prototype.$common.log;

            this.Login = async function (username, password) {
                try {
                    const data = await Vue.prototype.$XHR.api('cms_staff_login', {
                        username, password
                    });
                    devLog('----------> XHR [SUCCESS]: Login');
                    devLog(data)
                    return Promise.resolve({
                        id: data.id,
                        username: data.username,
                        user_type: data.role,
                        last_login: data.last_login
                    });
                } catch (res) {
                    devLog('----------> XHR [FAIL]: Login');
                    devLog(res);
                    switch (res.data) {
                        case 'user not found':
                            return Promise.reject('帳號錯誤');
                        case 'wrong password':
                            return Promise.reject('密碼錯誤');
                        default:
                            return Promise.reject(displayError(res));
                    }
                }
            }

            this.GetSetting = async function () {
                try {
                    const data = await Vue.prototype.$XHR.api('get_setting', {});
                    devLog('----------> XHR [SUCCESS]: GetSetting');
                    devLog(data);
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetSetting');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.SetSetting = async function (uploadedData) {
                try {
                    const payload = {
                        ...uploadedData
                    };
                    const data = await Vue.prototype.$XHR.api('cms_set_setting', payload);

                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: SetSetting');
                        return Promise.resolve(true);
                    } else {
                        devLog('----------> XHR [NULL]: SetSetting');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: SetSetting');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }


            this.GetNews = async function (filters = {}) {
                const payload = {
                    ...filters
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_news_list', payload);

                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        devLog('----------> XHR [SUCCESS]: GetNews');
                        devLog(data)
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetNews');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetNews');
                    devLog(res);
                    return Promise.reject([])
                }
            }

            this.DeleteNewsById = async function (id) {
                try {
                    const payload = {
                        id,
                    };
                    const data = await Vue.prototype.$XHR.api('cms_remove_news', payload);

                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: DeleteNewsById');
                        return Promise.resolve(true);
                    } else {
                        devLog('----------> XHR [NULL]: DeleteNewsById');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: DeleteNewsById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.AddNews = async function (uploadedData) {
                if (DataValid(uploadedData['status'])) {
                    delete uploadedData['status'];
                }

                try {
                    const payload = {
                        ...uploadedData,
                    };
                    const data = await Vue.prototype.$XHR.api('cms_add_news', payload);

                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: AddNews');
                        return Promise.resolve(true);
                    } else {
                        devLog('----------> XHR [NULL]: AddNews');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: AddNews');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetNewsById = async function (id) {
                const payload = {
                    id: id
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_news_by_id', payload);
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetNewsById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpdateNews = async function (id, uploadedData) {
                if (DataValid(uploadedData['id'])) {
                    delete uploadedData['id'];
                }

                try {
                    const payload = {
                        id: id,
                        ...uploadedData
                    };
                    const data = await Vue.prototype.$XHR.api('cms_update_news', payload);

                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: UpdateNews');
                        return Promise.resolve(true);
                    } else {
                        devLog('----------> XHR [NULL]: UpdateNews');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateNews');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }


            this.GetAppointments = async function (filters = {}) {
                const payload = {
                    ...filters
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_appointment_list', payload);

                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        devLog('----------> XHR [SUCCESS]: GetAppointments');
                        devLog(data)
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetAppointments');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetAppointments');
                    devLog(res);
                    return Promise.reject([])
                }
            }

            this.GetAppointmentById = async function (id) {
                const payload = {
                    id: id
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_appointment_by_id', payload);
                    return Promise.resolve(data);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetAppointmentById');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }

            this.UpdateAppointment = async function (id, uploadedData) {
                if (DataValid(uploadedData['id'])) {
                    delete uploadedData['id'];
                }

                try {
                    const payload = {
                        id: id,
                        ...uploadedData
                    };
                    const data = await Vue.prototype.$XHR.api('cms_update_appointment', payload);

                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: UpdateAppointment');
                        return Promise.resolve(true);
                    } else {
                        devLog('----------> XHR [NULL]: UpdateAppointment');
                        return Promise.reject("發生錯誤，請與我們聯絡");
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateAppointment');
                    devLog(res);
                    return Promise.reject(displayError(res));
                }
            }


            this.GetEnquiries = async function (filters = {}) {
                const payload = {
                    ...filters
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_enquiry_list', payload);

                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        devLog('----------> XHR [SUCCESS]: GetEnquiries');
                        devLog(data)
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetEnquiries');
                        return Promise.reject([]);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetEnquiries');
                    devLog(res);
                    return Promise.reject([])
                }
            }
        });
    },
}