<template>
  <v-app>
    <v-main class="pa-0" style="background-color: #FAFAFA;">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {}
</script>

<style scoped>
.copyright {
  font-size: .8rem;
}
</style>
