


export default {
    install(Vue, option) {
        Vue.prototype.$validate = new (function () {
            const DataValid = function (data) {
                if (data !== undefined && data !== null && data !== '') {
                    if (typeof data === 'object') {
                        return Object.keys(data).length > 0
                    } else if (Array.isArray(data)) {
                        return data.length > 0
                    }

                    if (data.length > 0) {
                        return data.trim().length > 0
                    }
                    return true;

                } else {
                    return false
                }
            }

            const regex = {
                email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                // phone: /^[2-9][0-9]{7}$/,
                phone: /^(\+?)[0-9]{2,18}$/i,
                hkMobile: /^[3-9][0-9]{7}$/,
                number: /^[0-9]+$/i,
                // floating: /^[0-9]+([.]?[0-9]{1,2})?$/i,
                floating: /^[0-9]+([.]?[0-9]{1})?$/i,
                floatingWithoutLimit: /^[0-9]+([.]?[0-9]+)?$/i,
                negativeNumber: /^[-]?[0-9]+$/i,
                negativeFloating: /^[-]?[0-9]+([.]?[0-9]+)?$/i,
                // blogSlug: /^[a-zA-Z0-9-_]+$/i
                blogSlug: /^[\u4e00-\u9fff\u3400-\u4dff\uf900-\ufaffA-za-z0-9-_]+$/i,
                hkid4Letter: /^[A-Z]{1,2}[0-9]{4}$/,
                date: /^\d{4}-\d{2}-\d{2}$/, // YYYY-MM-DD
                regexIdNum: /^[a-zA-Z0-9]{8,9}$/i,
                hkPhone: /^(^[2|3|4|5|6|7|8|9])+([0-9]{7})$/,
                twPhone: /^(^[1-9])+([0-9]{8})$/,
                macauPhone: /^([0-9]{8})$/,
                chinaPhone: /^(^[1])+([0-9]{10})$/,
                chinName: /^[\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u017F\u3400-\u4DBF\u4E00-\u9FFF]{2,40}$/,
                engName: /^[a-zA-Z\s.]{2,40}$/,
                password: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
                styleCode: /^[aAsSbBlLxX][0-9]{4}$/,
            }

            this.DataValid = data => {
                return DataValid(data);
            }

            this.required = value => {
                if (DataValid(value) && typeof value === 'string') {
                    return value.trim().length > 0
                }

                return DataValid(value);
            }

            this.regexNumber = (value) => {
                return regex.number.test(value);
            }

            this.regexFloating = (value) => {
                return regex.floatingWithoutLimit.test(value);
            }

            this.regexDateStr = (value) => {
                const valid = regex.date.test(value);
                if (!valid) {
                    return false;
                }

                const month = parseInt(value.substring(5, 7));
                if (month < 1 || month > 12) {
                    return false;
                }

                const daysInMonths = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
                const date = parseInt(value.substring(8, 10));

                if (date < 1 || date > daysInMonths[month - 1]) {
                    return false;
                }

                return true;
            }

            this.isNumber = (value, allowEmpty = false) => {
                if (!DataValid(value)) {
                    if (allowEmpty) {
                        return true;
                    } else {
                        return '此位置不能留空';
                    }
                }

                return regex.number.test(value) || '請輸入有效數字'
            }

            this.isFloating = (value, allowEmpty = false) => {
                if (!DataValid(value)) {
                    if (allowEmpty) {
                        return true;
                    } else {
                        return '此位置不能留空';
                    }
                }

                return regex.floating.test(value) || '請輸入有效數字'
            }

            this.isPhoneNumber = (value, allowEmpty = false) => {
                if (!DataValid(value)) {
                    if (allowEmpty) {
                        return true;
                    } else {
                        return '此位置不能留空';
                    }
                }

                return regex.phone.test(value) || '請輸入有效電話號碼'
            }

            this.isFaxNumber = (value, allowEmpty = false) => {
                if (!DataValid(value)) {
                    if (allowEmpty) {
                        return true;
                    } else {
                        return '此位置不能留空';
                    }
                }

                return regex.number.test(value) || '傳真號碼格式不正確'
            }

            this.isEmail = (value, allowEmpty = false) => {
                if (!DataValid(value)) {
                    if (allowEmpty) {
                        return true;
                    } else {
                        return '此位置不能留空';
                    }
                }

                return regex.email.test(value) || '請輸入有效電郵'
            }

            this.isEngName = (value, allowEmpty = false) => {
                if (!DataValid(value)) {
                    if (allowEmpty) {
                        return true;
                    } else {
                        return '此位置不能留空';
                    }
                }

                return regex.engName.test(value) || '英文名稱格式不正確'
            }

            this.isChinName = (value, allowEmpty = false) => {
                if (!DataValid(value)) {
                    if (allowEmpty) {
                        return true;
                    } else {
                        return '此位置不能留空';
                    }
                }

                return regex.chinName.test(value) || '中文名稱格式不正確'
            }

            this.isSlug = (value, allowEmpty = false) => {
                if (!DataValid(value)) {
                    if (allowEmpty) {
                        return true;
                    } else {
                        return '此位置不能留空';
                    }
                }

                return regex.blogSlug.test(value) || '請輸入有效URL Slug'
            }

            this.isValidPassword = (value, allowEmpty = false) => {
                if (!DataValid(value)) {
                    if (allowEmpty) {
                        return true;
                    } else {
                        return '此位置不能留空';
                    }
                }

                return true;
                // return regex.password.test(value) || '請輸入最少8個英文或數字字元作為密碼'
            }

            this.checkDoubleFileExtension = (fileName) => {
                const extension = fileName.match(/\.(.+)$/)[1];

                if (extension.split(".").length !== 1) {
                    return true;
                }

                return false;
            }

            this.validateFileInput = (file, requiredExtension) => {
                // requiredType = img, video...
                // requiredExtension = png, jpg, mp4...

                const limitFileSize = 300;

                let result = '';
                const fileType = file.type;
                const format = fileType.split('/')[1];

                if (this.checkDoubleFileExtension(file.name)) {
                    result = '請確保上傳的檔案名稱沒有「.」符號';                
                } else if (!requiredExtension.includes('*') && !requiredExtension.includes(format)) {
                    result = `只可上傳 ${requiredExtension.join(', ')} 檔案`;
                } else if (file.size / 1000 / 1000 > limitFileSize) {
                    result = `檔案大小不可大於 ${limitFileSize}MB`;
                }

                return result;
            }
        })()
    }
}